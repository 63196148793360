import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';

import { getRequest } from 'utils/api';
import { setCounts } from 'features/gigsSlice';
import { getMasquerading } from 'utils/localStorage';
import useIsBuyer from 'hooks/useIsBuyer';
import useIsInfluencer from 'hooks/useIsInfluencer';
import HouseIcon from 'public/icons/house.svg';
import JobsInIcon from 'public/icons/jobs-in.svg';
import GigsIcon from 'public/icons/loggedInFooter/gigs.svg';
import usePrimaryInterest from 'hooks/usePrimaryInterest';
import JobsOutIcon from 'public/icons/jobs-out.svg';
import ActiveGigsIcon from 'public/icons/loggedInFooter/active-gigs.svg';
import OpenGigsIcon from 'public/icons/loggedInFooter/open-gigs.svg';
import SearchIcon from 'public/icons/search.svg';
import NavLink from 'components/NavLink';
import Badge from 'components/Badge';
import SwitchPrimaryInterest from 'components/SwitchPrimaryInterest';
import styles from 'styles/components/agency/Sidebar.module.scss';

const InfluencerDesktopSidebar = () => {
  const [gigCounts, setGigCounts] = useState({});
  const [masquerading, setMasquerading] = useState(false);
  const router = useRouter();
  const loggedIn = useSelector(state => state.auth.loggedIn);
  const isInfluencer = useIsInfluencer();
  const isBuyer = useIsBuyer();
  const primaryInterest = usePrimaryInterest();
  const dispatch = useDispatch();
  const realCounts = useSelector(state => state.gigs.counts);
  const currentUser = useSelector(state => state.auth.currentUser);
  const managingProfile =
    currentUser?.full_management_agency && currentUser?.currently_managing_profile;

  const getSidebarPosition = () => {
    // If the Links Page there is no header
    if (router.pathname === '/[username]') return 30;
    if (masquerading && managingProfile) return 170;
  };

  useEffect(() => {
    if (loggedIn) {
      getRequest({
        endpoint: 'gigs/status_counts',
      })
        .then(response => {
          setGigCounts(response);
          dispatch(setCounts(response));
        })
        .catch(() => {});
    }

    return null;
  }, [loggedIn, primaryInterest, dispatch]);

  useEffect(() => {
    if (!realCounts) return;

    setGigCounts(realCounts);
  }, [realCounts]);

  useEffect(() => {
    const masquerading = getMasquerading();
    setMasquerading(masquerading);
  }, []);

  return (
    <nav className={styles.sidebar}>
      <ul
        className={managingProfile || masquerading ? styles.masqueradingTop : styles.top}
        style={{ top: getSidebarPosition() }}
      >
        <div className={styles.switchButton}>
          <SwitchPrimaryInterest />
        </div>

        <NavLink href="/dashboard" activeClassName={styles.active}>
          <li className={styles.heading}>
            <HouseIcon className={styles.userIcon} />
            <h3>Dashboard</h3>
          </li>
        </NavLink>

        <NavLink href="/offers" activeClassName={styles.active}>
          <Badge content={gigCounts.orders} badgeStyles={{ top: 7, left: 32, right: 'auto' }}>
            <li className={styles.heading}>
              {isBuyer ? (
                <JobsOutIcon className={styles.navIcons} width={30} height={30} alt="Jobs Out" />
              ) : (
                <JobsInIcon className={styles.navIcons} width={30} height={30} alt="Jobs In" />
              )}

              <h3>Orders {!isBuyer && '& Invites'}</h3>
            </li>
          </Badge>
        </NavLink>

        {isBuyer && (
          <NavLink href="/gigs/open" activeClassName={styles.active}>
            <Badge content={gigCounts.open} badgeStyles={{ top: 7, left: 32, right: 'auto' }}>
              <li className={styles.heading}>
                <OpenGigsIcon width={30} height={30} alt="Suitcase" />

                <h3>Public offers</h3>
              </li>
            </Badge>
          </NavLink>
        )}

        <NavLink href="/gigs/active" activeClassName={styles.active}>
          <Badge content={gigCounts.active} badgeStyles={{ top: 7, left: 32, right: 'auto' }}>
            <li className={styles.heading}>
              <ActiveGigsIcon width={30} height={30} alt="Suitcase" />

              <h3>Active gigs</h3>
            </li>
          </Badge>
        </NavLink>

        <NavLink href="/gigs/closed" activeClassName={styles.active}>
          <li className={styles.heading}>
            <GigsIcon width={30} height={30} alt="Search" />

            <h3>Archived</h3>
          </li>
        </NavLink>

        {isBuyer && (
          <NavLink href="/gigs/draft" activeClassName={styles.active}>
            <li className={styles.heading}>
              <GigsIcon width={30} height={30} alt="Suitcase" />

              <h3>Gig Drafts</h3>
            </li>
          </NavLink>
        )}

        {isInfluencer && (
          <NavLink href={'/discover'} activeClassName={styles.active}>
            <li className={styles.heading}>
              <SearchIcon className={styles.searchIcon} width={30} height={30} alt="Search" />

              <h3>Discover</h3>
            </li>
          </NavLink>
        )}
      </ul>
    </nav>
  );
};

export default InfluencerDesktopSidebar;
