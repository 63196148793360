import auth from 'features/authSlice';
import influencerSetup from 'features/influencerSetupSlice';
import gigSetup from 'features/gigSetupSlice';
import utils from 'features/utilsSlice';
import filterInventory from 'features/filterInventorySlice';
import cart from 'features/cartSlice';
import gigs from 'features/gigsSlice';
import profiles from 'features/profilesSlice';
import advertiserProfile from 'features/advertiserProfileSlice';
import discoverFilters from 'features/discoverFiltersSlice';

import { createStore, compose, combineReducers } from 'redux';
import { HYDRATE, createWrapper } from 'next-redux-wrapper';

const composeEnhancers =
  (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const combinedReducer = combineReducers({
  auth,
  influencerSetup,
  gigSetup,
  utils,
  filterInventory,
  cart,
  gigs,
  profiles,
  advertiserProfile,
  discoverFilters,
});

const reducer = (state, action) => {
  if (action.type === HYDRATE) {
    const { gigs, profiles, advertiserProfile } = action.payload;

    return { ...state, gigs, profiles, advertiserProfile };
  } else {
    return combinedReducer(state, action);
  }
};

const initStore = () => createStore(reducer, composeEnhancers());

export const wrapper = createWrapper(initStore);
