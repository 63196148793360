import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import { useSelector } from 'react-redux';
import { Avatar } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';

import { getRequest } from 'utils/api';
import usePrimaryInterest from 'hooks/usePrimaryInterest';
import useCurrentProfile from 'hooks/useCurrentProfile';
import useIsBuyer from 'hooks/useIsBuyer';
import HomeIcon from 'public/icons/loggedInFooter/home.svg';
import ActiveGigsIcon from 'public/icons/loggedInFooter/active-gigs.svg';
import JobsInIcon from 'public/icons/loggedInFooter/jobs-in.svg';
import JobsOutIcon from 'public/icons/loggedInFooter/jobs-out.svg';
import DiscoverIcon from 'public/icons/loggedInFooter/discover.svg';
import NoAvatarImage from 'public/images/no-avatar.svg';
import NavLink from 'components/NavLink';
import Badge from 'components/Badge';
import styles from 'styles/components/LoggedInFooter.module.scss';

const avatarSize = { width: 33, height: 33 };
const avatarFullSize = { width: '100%', height: '100%' };

const InfluencerFooter = () => {
  const [counts, setCounts] = useState({});
  const loggedIn = useSelector(state => state.auth.loggedIn);
  const isBuyer = useIsBuyer();
  const primaryInterest = usePrimaryInterest();
  const profile = useCurrentProfile();

  useEffect(() => {
    if (loggedIn) {
      getRequest({
        endpoint: 'gigs/status_counts',
      })
        .then(response => setCounts(response))
        .catch(() => {});
    }

    return null;
  }, [loggedIn, primaryInterest]);

  return (
    <Hidden mdUp>
      <footer className={styles.footer}>
        <div className={styles.container}>
          <NavLink href="/dashboard" activeClassName={styles.active} exact>
            <div className={styles.navIcons}>
              <HomeIcon width={30} height={30} alt="Home" />

              <span>Dashboard</span>
            </div>
          </NavLink>

          <NavLink href="/offers" activeClassName={styles.active}>
            <Badge content={counts.orders} badgeStyles={{ top: '-5px', right: '-10px' }}>
              <div className={styles.navIcons}>
                {isBuyer ? (
                  <JobsOutIcon className={styles.navIcons} width={30} height={30} alt="Jobs Out" />
                ) : (
                  <JobsInIcon className={styles.navIcons} width={30} height={30} alt="Jobs In" />
                )}

                <span>Orders</span>
              </div>
            </Badge>
          </NavLink>

          <NavLink href="/gigs/active" activeClassName={styles.active}>
            <Badge content={counts.active} badgeStyles={{ top: '-5px', right: '-10px' }}>
              <div className={styles.navIcons}>
                <ActiveGigsIcon width={30} height={30} alt="Suitcase" />

                <span>Active gigs</span>
              </div>
            </Badge>
          </NavLink>

          <NavLink href={'/discover'} activeClassName={styles.active}>
            <div className={styles.navIcons}>
              <DiscoverIcon width={30} height={30} alt="Search" />

              <span>Discover</span>
            </div>
          </NavLink>

          <NavLink href={`/u/${profile?.username}`} activeClassName={styles.active}>
            <div className={styles.profileLink}>
              <Avatar src={profile?.avatar_url} style={avatarSize} alt="Cover">
                <NoAvatarImage style={avatarFullSize} />
              </Avatar>

              <span>Profile</span>
            </div>
          </NavLink>
        </div>
      </footer>
    </Hidden>
  );
};

export default InfluencerFooter;
