import { createSlice } from '@reduxjs/toolkit';

export const gigsSlice = createSlice({
  name: 'gigs',
  initialState: {
    orders: [],
    mapping: {},
    invitation: {},
    counts: {
      orders: 0,
      open: 0,
      invites: 0,
      pending: 0,
      active: 0,
      completed: 0,
      cancelled: 0,
      archived: 0,
      applications: 0,
    },
  },

  reducers: {
    setOrders: (state, action) => {
      state.orders = action.payload;
    },

    setGig: (state, action) => {
      state.mapping[action.payload.id] = action.payload;
    },

    setInvitation: (state, action) => {
      state.invitation[action.payload.id] = action.payload;
    },

    setCounts: (state, action) => {
      state.counts = action.payload;
    },

    incrementCount: (state, action) => {
      state.counts = { ...state.counts, [action.payload]: state.counts[action.payload] + 1 };
    },
  },
});

export const { setOrders, setGig, setInvitation, setCounts, incrementCount } = gigsSlice.actions;

export default gigsSlice.reducer;
