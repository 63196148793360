import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';

import { getRequest } from 'utils/api';
import usePrimaryInterest from 'hooks/usePrimaryInterest';
import HouseIcon from 'public/icons/house.svg';
import GigsIcon from 'public/icons/loggedInFooter/gigs.svg';
import ActiveGigsIcon from 'public/icons/loggedInFooter/active-gigs.svg';
import OpenGigsIcon from 'public/icons/loggedInFooter/open-gigs.svg';
import SearchIcon from 'public/icons/search.svg';
import NavLink from 'components/NavLink';
import Badge from 'components/Badge';
import useCurrentUser from 'hooks/useCurrentUser';
import styles from 'styles/components/agency/Sidebar.module.scss';

const AdvertiserDesktopSidebar = () => {
  const [counts, setCounts] = useState({});
  const loggedIn = useSelector(state => state.auth.loggedIn);
  const router = useRouter();
  const currentUser = useCurrentUser();
  const primaryInterest = usePrimaryInterest();

  useEffect(() => {
    if (loggedIn) {
      getRequest({
        endpoint: 'gigs/status_counts',
      })
        .then(response => setCounts(response))
        .catch(() => {});
    }

    return null;
  }, [loggedIn, primaryInterest]);

  const getSidebarPosition = () => {
    // If the Links Page there is no header
    if (router.pathname === '/[username]') return 30;
  };

  const closed = counts.completed + counts.cancelled + counts.archived;

  return (
    <nav className={styles.sidebar}>
      <ul className={styles.top} style={{ top: getSidebarPosition() }}>
        <NavLink href="/dashboard" activeClassName={styles.active}>
          <li className={styles.heading}>
            <HouseIcon className={styles.userIcon} />
            <h3>Dashboard</h3>
          </li>
        </NavLink>

        {currentUser?.agency && (
          <NavLink href="/discover" activeClassName={styles.active}>
            <li className={styles.heading}>
              <SearchIcon className={styles.searchIcon} width={30} height={30} alt="Search" />

              <h3>Discover</h3>
            </li>
          </NavLink>
        )}

        <NavLink href="/gigs/open" activeClassName={styles.active}>
          <Badge content={counts.open} badgeStyles={{ top: 7, left: 32, right: 'auto' }}>
            <li className={styles.heading}>
              <OpenGigsIcon width={30} height={30} alt="Suitcase" />

              <h3>Public offers</h3>
            </li>
          </Badge>
        </NavLink>

        <NavLink href="/gigs/active" activeClassName={styles.active}>
          <Badge content={counts.active} badgeStyles={{ top: 7, left: 32, right: 'auto' }}>
            <li className={styles.heading}>
              <ActiveGigsIcon width={30} height={30} alt="Suitcase" />

              <h3>Active gigs</h3>
            </li>
          </Badge>
        </NavLink>

        <NavLink href="/gigs/closed" activeClassName={styles.active}>
          <li className={styles.heading}>
            <GigsIcon width={30} height={30} alt="Suitcase" />

            <h3>Archived</h3>
          </li>
        </NavLink>

        <NavLink href="/gigs/draft" activeClassName={styles.active}>
          <li className={styles.heading}>
            <GigsIcon width={30} height={30} alt="Suitcase" />

            <h3>Gig Drafts</h3>
          </li>
        </NavLink>
      </ul>
    </nav>
  );
};

export default AdvertiserDesktopSidebar;
