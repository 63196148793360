import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Hidden from '@material-ui/core/Hidden';

import { getRequest } from 'utils/api';
import usePrimaryInterest from 'hooks/usePrimaryInterest';
import useCurrentUser from 'hooks/useCurrentUser';
import HomeIcon from 'public/icons/loggedInFooter/home.svg';
import GigsIcon from 'public/icons/loggedInFooter/gigs.svg';
import OpenGigsIcon from 'public/icons/loggedInFooter/open-gigs.svg';
import ActiveGigsIcon from 'public/icons/loggedInFooter/active-gigs.svg';
import AddIcon from 'public/icons/add-main.svg';
import DiscoverIcon from 'public/icons/loggedInFooter/discover.svg';
import NavLink from 'components/NavLink';
import Badge from 'components/Badge';
import styles from 'styles/components/LoggedInFooter.module.scss';

const AdvertiserFooter = () => {
  const [counts, setCounts] = useState({});
  const loggedIn = useSelector(state => state.auth.loggedIn);
  const primaryInterest = usePrimaryInterest();
  const currentUser = useCurrentUser();

  useEffect(() => {
    if (loggedIn) {
      getRequest({
        endpoint: 'gigs/status_counts',
      })
        .then(response => setCounts(response))
        .catch(() => {});
    }

    return null;
  }, [loggedIn, primaryInterest]);

  const closed = counts.completed + counts.cancelled + counts.archived;

  return (
    <Hidden mdUp>
      <footer className={styles.footer}>
        <div className={styles.container}>
          <NavLink href="/dashboard" activeClassName={styles.active} exact>
            <div className={styles.navIcons}>
              <HomeIcon width={30} height={30} alt="Home" />

              <span>Dashboard</span>
            </div>
          </NavLink>

          <NavLink href="/gigs/open" activeClassName={styles.active}>
            <Badge content={counts.open} badgeStyles={{ top: '-5px', right: '-10px' }}>
              <div className={styles.navIcons}>
                <OpenGigsIcon width={30} height={30} alt="Suitcase" />

                <span>Public offers</span>
              </div>
            </Badge>
          </NavLink>

          <NavLink href="/gigs/active" activeClassName={styles.active}>
            <Badge content={counts.active} badgeStyles={{ top: '-5px', right: '-10px' }}>
              <div className={styles.navIcons}>
                <ActiveGigsIcon width={30} height={30} alt="Suitcase" />

                <span>Active gigs</span>
              </div>
            </Badge>
          </NavLink>

          <NavLink href="/gigs/closed" activeClassName={styles.active}>
            <div className={styles.navIcons}>
              <GigsIcon width={30} height={30} alt="Suitcase" />

              <span>Archived</span>
            </div>
          </NavLink>

          {currentUser?.agency ? (
            <NavLink href="/discover" activeClassName={styles.active}>
              <div className={styles.navIcons}>
                <DiscoverIcon width={30} height={30} alt="Search" />

                <span>Discover</span>
              </div>
            </NavLink>
          ) : (
            <NavLink href="/gigs/new" activeClassName={styles.active}>
              <div className={styles.navIcons}>
                <AddIcon className={styles.addIcon} width={30} height={30} alt="Plus sign" />

                <span>Add gig</span>
              </div>
            </NavLink>
          )}
        </div>
      </footer>
    </Hidden>
  );
};

export default AdvertiserFooter;
