import { createSlice } from '@reduxjs/toolkit';

export const discoverFiltersSlice = createSlice({
  name: 'discoverFilters',
  initialState: {
    traitIds: [],
    popularityConditions: {},
    filter: 'all',
    rating: 0,
    gender: 'all',
    sort: 'relevance',
  },

  reducers: {
    setDiscoverPopularityConditions: (state, action) => {
      state.popularityConditions = action.payload;
    },

    setDiscoverFilter: (state, action) => {
      state.filter = action.payload;
    },

    setDiscoverRating: (state, action) => {
      state.rating = action.payload;
    },

    setDiscoverGender: (state, action) => {
      state.gender = action.payload;
    },

    setDiscoverSort: (state, action) => {
      state.sort = action.payload;
    },

    setDiscoverTraitIds: (state, action) => {
      console.log('action', action);
      state.traitIds = action.payload;
    },
  },
});

export const {
  setDiscoverPopularityConditions,
  setDiscoverFilter,
  setDiscoverRating,
  setDiscoverGender,
  setDiscoverSort,
  setDiscoverTraitIds,
} = discoverFiltersSlice.actions;

export default discoverFiltersSlice.reducer;
